﻿import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

//retrieve apiKey from environment variable and set const
const apiKey4Real = import.meta.env.VITE_FIREBASE_API_KEY;

//firebase config
const firebaseConfig = {
  apiKey: apiKey4Real, 
  authDomain: "chat-magic-752a8.firebaseapp.com",
  projectId: "chat-magic-752a8",
  storageBucket: "chat-magic-752a8.appspot.com",
  messagingSenderId: "384802000917",
  appId: "1:384802000917:web:a196d269153ec1fb503e1c",
  measurementId: "G-ELYWZ1HQ5V"
};

//initialize firebase
const app = initializeApp(firebaseConfig);

//initialize analytics
const analytics = getAnalytics(app);



// -- CHECK IF WEBSITE IS OPENED ON MOBILE BROWSER -------------------------------- //

// if the website is opened in a mobile browser, set
// 'the-wiz-desktop' left-margin property to 0 to help
// avoid horizontal scrolling on a mobile device

function adjustMarginForMobile() {

    console.log("adjustMarginForMobile() is being called");

    // Detecting mobile browser
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // Getting the element by ID
    var theWizDiv = document.getElementById('the-wiz-desktop');

    // Check if the element exists and the browser is a mobile browser
    if (isMobile && theWizDiv) {
        theWizDiv.style.marginLeft = '0px';
        
    }

    if (isMobile) {
      console.log('isMobile is set to true')
      displayVideoUI;
    }
}




// -- ANIMATE IN THE WIZ AND THE DESKTOP NAV ------------------------------------- //

const theWizDesktop = document.getElementById('the-wiz-desktop');
const desktopNavBar = document.getElementById('nav-desktop');

var loadedOnDesktop = false;

window.onload = () => {
  if (window.innerWidth >= 768) {
    loadedOnDesktop = true;
  }
  if (window.innerWidth < 767) {
    loadedOnDesktop = false;
  }
  animateInTheWizDesktop();
  animateInDesktopNavBar();
  adjustMarginForMobile();
  animateWand();
}

//listen for when the browser window size changes
window.addEventListener('resize', animateInDesktopNavBar);

//listen for when the browser window size changes
window.addEventListener('resize', animateInTheWizDesktop);

//animate in the Wiz on desktop
function animateInTheWizDesktop() {

  if (window.innerWidth >= 1333) {
    if (myVideo.paused) {
      theWizDesktop.classList.remove("the-wiz-desktop-show-partially");
      theWizDesktop.classList.add("the-wiz-desktop-show");
    }
  }
  else {
    theWizDesktop.classList.remove("the-wiz-desktop-show");
  }
}

//animate out the Wiz on desktop <-- this will only occur when the video is playing
function animateOutTheWizDesktop() {

  if (window.innerWidth >= 1333) {
    theWizDesktop.classList.remove("the-wiz-desktop-show");
  }
}

function animateOutTheWizDesktopPartially() {

  if (window.innerWidth >= 1333) {
    theWizDesktop.classList.remove("the-wiz-desktop-show");
    theWizDesktop.classList.add("the-wiz-desktop-show-partially");
  }
}

//animate in the Nav on desktop
function animateInDesktopNavBar() {

  if (window.innerWidth >= 768) {
    if (loadedOnDesktop) {
      desktopNavBar.style.transition = .65 + "s";
    }
    else {
      desktopNavBar.style.transition = .35 + "s";
    }
    desktopNavBar.classList.add("nav-desktop-show");
  }
  else {
    desktopNavBar.style.transition = .35 + "s";
    desktopNavBar.classList.remove("nav-desktop-show");
  }

}


// -- ACTIVE ITEM OF DESKTOP AND MOBILE NAV -------------------------------------- //


const sections = document.querySelectorAll("section");
const navDesktopItems= document.querySelectorAll(".nav-desktop-item");
const mobileNavDots = document.querySelectorAll(".dot");

window.onscroll = () => {
  
  var current = "";

  sections.forEach((section) => {
    const sectionTop = section.offsetTop;
    if (scrollY >= sectionTop - 60) {
      current = section.getAttribute("id"); }
  });

  navDesktopItems.forEach((div) => {
    div.style.transition = 'none';
    div.classList.remove("active-white");
    div.classList.remove("active-black");
    if (div.classList.contains(current)) {
      if (div.classList.contains('black')) {
        div.classList.add("active-black");
      }
      else {
        div.classList.add("active-white");
      }
    }
  });

  mobileNavDots.forEach((div) => {
    div.classList.remove("mobile-active");
    if (div.classList.contains(current)) {
      div.classList.add("mobile-active");;
    }
  });
};


// -- SCROLLING TO SECTION FOR BOTH MOBILE AND DESKTOP ------------------- //


function scrollTo(element) {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: element.offsetTop
  });
}


// -- CLICK/TAP MAIN CTA AND SCROLL TO SECTION -- //


document.getElementById("main-cta").addEventListener('click', () => {

  logEvent(analytics, 'view_plans_cta_clicked');

  if (window.innerWidth < 768) {
    scrollToMobile(document.getElementById("plans"));
  }
  else {
    scrollTo(document.getElementById("plans"));
  }
});


// -- CLICK/TAP DESKTOP NAV BAR AND SCROLL TO SECTION -- //


document.getElementById("nav-desktop-new-home").addEventListener('click', () => {
  scrollTo(document.getElementById("home"));
  logEvent(analytics, 'desktop_nav_home_clicked');
});

document.getElementById("nav-desktop-new-services").addEventListener('click', () => {
  scrollTo(document.getElementById("services"));
  logEvent(analytics, 'desktop_nav_services_clicked');
});

document.getElementById("nav-desktop-new-process").addEventListener('click', () => {
  scrollTo(document.getElementById("process"));
  logEvent(analytics, 'desktop_nav_process_clicked');
});

document.getElementById("nav-desktop-new-work").addEventListener('click', () => {
  scrollTo(document.getElementById("work"));
  logEvent(analytics, 'desktop_nav_work_clicked');
});

document.getElementById("nav-desktop-new-plans").addEventListener('click', () => {
  scrollTo(document.getElementById("plans"));
  logEvent(analytics, 'desktop_nav_plans_clicked');
});

document.getElementById("nav-desktop-new-faq").addEventListener('click', () => {
  scrollTo(document.getElementById("faq"));
  logEvent(analytics, 'desktop_nav_faq_clicked');
});



// -- HAMBURGER MENU FUNCTIONALITY ---------------------------- //


const logo = document.getElementById('logo');
const hamburgerMenu = document.getElementById('hamburger');
const hamburgerMenuBar1 = document.getElementById('bar-1');
const hamburgerMenuBar2 = document.getElementById('bar-2');
const hamburgerMenuBar3 = document.getElementById('bar-3');
const header = document.getElementById('header');
const mobile_menu = document.getElementById('mobile-nav');
const header_mask = document.getElementById('header-mask');
const headerWavePurpleMain = document.getElementById('header-wave-purple-main');
const headerWavePurpleDark = document.getElementById('header-wave-purple-dark');
let mobileNavHome = document.getElementById("nav-home-mobile");
let mobileNavHomeParent = mobileNavHome.parentElement;
let hamburgerMenuIsActive = false;

//slide menu in and out
hamburgerMenu.addEventListener('click', function () {
  handleHeaderColor();
  header.classList.toggle('is-active');
  mobile_menu.classList.toggle('is-active');
  header_mask.classList.toggle('is-active');
});

function handleHeaderColor() {
  if (!hamburgerMenuIsActive) {
    hamburgerMenuIsActive = true;
    logo.classList.add('is-not-visible')
    logo.classList.remove('is-visible')
    hamburgerMenuBar1.classList.add('rotation-top');
    hamburgerMenuBar2.classList.add('adjust-margin');
    hamburgerMenuBar3.classList.add('rotation-bottom');
    logEvent(analytics, 'hamburger_opened');
  }
  else {
    hamburgerMenuIsActive = false;
    logo.classList.add('is-visible')
    logo.classList.remove('is-not-visible')
    hamburgerMenuBar1.classList.remove('rotation-top');
    hamburgerMenuBar2.classList.remove('adjust-margin');
    hamburgerMenuBar3.classList.remove('rotation-bottom');
    logEvent(analytics, 'hamburger_closed');
  }
  handleHeaderColors();
}


mobileNavHomeParent.addEventListener('click', () => {
  setTimeout(scrollToHome, 500);
  handleHeaderColor();
  logo.classList.add('is-visible')
  logo.classList.remove('is-not-visible')
  header.classList.toggle('is-active');
  mobile_menu.classList.toggle('is-active');
  header_mask.classList.toggle('is-active');
  logEvent(analytics, 'mobile_nav_home_clicked');

});

function scrollToHome() {
  scrollToMobile(document.getElementById("header"));
}


let mobileNavServices = document.getElementById("nav-services-mobile");
let mobileNavServicesParent = mobileNavServices.parentElement;

mobileNavServicesParent.addEventListener('click', () => {
  setTimeout(scrollToServices, 500);
  handleHeaderColor();
  logo.classList.add('is-visible')
  logo.classList.remove('is-not-visible')
  header.classList.toggle('is-active');
  mobile_menu.classList.toggle('is-active');
  header_mask.classList.toggle('is-active');
  logEvent(analytics, 'mobile_nav_services_clicked');
});

function scrollToServices() {
  scrollToMobile(document.getElementById("services"));
}


let mobileNavProcess = document.getElementById("nav-process-mobile");
let mobileNavProcessParent = mobileNavProcess.parentElement;

mobileNavProcessParent.addEventListener('click', () => {
  setTimeout(scrollToProcess, 500);
  handleHeaderColor();
  logo.classList.add('is-visible')
  logo.classList.remove('is-not-visible')
  header.classList.toggle('is-active');
  mobile_menu.classList.toggle('is-active');
  header_mask.classList.toggle('is-active');
  logEvent(analytics, 'mobile_nav_process_clicked');
});

function scrollToProcess() {
  scrollToMobile(document.getElementById("process"));
}


let mobileNavWork = document.getElementById("nav-work-mobile");
let mobileNavWorkParent = mobileNavWork.parentElement;

mobileNavWorkParent.addEventListener('click', () => {
  setTimeout(scrollToWork, 500);
  handleHeaderColor();
  logo.classList.add('is-visible')
  logo.classList.remove('is-not-visible')
  header.classList.toggle('is-active');
  mobile_menu.classList.toggle('is-active');
  header_mask.classList.toggle('is-active');
  logEvent(analytics, 'mobile_nav_work_clicked');
});

function scrollToWork() {
  scrollToMobile(document.getElementById("work"));
}


let mobileNavPlans = document.getElementById("nav-plans-mobile");
let mobileNavPlansParent = mobileNavPlans.parentElement;

mobileNavPlansParent.addEventListener('click', () => {
  setTimeout(scrollToPlans, 500);
  handleHeaderColor();
  logo.classList.add('is-visible')
  logo.classList.remove('is-not-visible')
  header.classList.toggle('is-active');
  mobile_menu.classList.toggle('is-active');
  header_mask.classList.toggle('is-active');
  logEvent(analytics, 'mobile_nav_plans_clicked');
});

function scrollToPlans() {
  scrollToMobile(document.getElementById("plans"));
}


let mobileNavFaq = document.getElementById("nav-faq-mobile");
let mobileNavFaqParent = mobileNavFaq.parentElement;

mobileNavFaqParent.addEventListener('click', () => {
  setTimeout(scrollToFaq, 500);
  handleHeaderColor();
  logo.classList.add('is-visible')
  logo.classList.remove('is-not-visible')
  header.classList.toggle('is-active');
  mobile_menu.classList.toggle('is-active');
  header_mask.classList.toggle('is-active');
  logEvent(analytics, 'mobile_nav_faq_clicked');
});

function scrollToFaq() {
  scrollToMobile(document.getElementById("faq"));
}

function scrollToMobile(element) {
    var headerOffset = 55;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: offsetPosition
  });
}

function handleHeaderColors() {

  if (window.innerWidth >= 768) {
    if (hamburgerMenuIsActive) {
      hamburgerMenuIsActive = false;
      header.classList.toggle('is-active');
      mobile_menu.classList.toggle('is-active');
      header_mask.classList.toggle('is-active');
      hamburgerMenuBar1.classList.remove('rotation-top');
      hamburgerMenuBar2.classList.remove('adjust-margin');
      hamburgerMenuBar3.classList.remove('rotation-bottom');
      headerWavePurpleMain.style.opacity = 1;
      headerWavePurpleDark.style.opacity = 0;

    }
    else if (!hamburgerMenuIsActive) {
      header.classList.remove('black');
    }
  }
  else {
    if (hamburgerMenuIsActive) {
        header.classList.add('black');

        hamburgerMenuBar1.classList.add('white');
        hamburgerMenuBar1.classList.remove('dark-purple');

        hamburgerMenuBar2.classList.add('dark-purple');
        hamburgerMenuBar2.classList.remove('white');

        hamburgerMenuBar3.classList.add('white');
        hamburgerMenuBar3.classList.remove('dark-purple');

        mobileNavDots.forEach((div) => {div.classList.add('dark-purple')});
        mobileNavDots.forEach((div) => {div.classList.remove('purple-main')});

        headerWavePurpleMain.style.opacity = 0;
        headerWavePurpleDark.style.opacity = 1;
    }
    else if (!hamburgerMenuIsActive) {
        header.classList.remove('black');
        hamburgerMenuBar1.classList.add('white');
        hamburgerMenuBar1.classList.remove('dark-purple');
        hamburgerMenuBar2.classList.add('white');
        hamburgerMenuBar2.classList.remove('dark-purple');
        hamburgerMenuBar3.classList.add('white');
        hamburgerMenuBar3.classList.remove('dark-purple');
        
        mobileNavDots.forEach((div) => {div.classList.add('light-purple')});
        mobileNavDots.forEach((div) => {div.classList.remove('dark-purple')});

        headerWavePurpleMain.style.opacity = 1;
        headerWavePurpleDark.style.opacity = 0;
    }
  }
}

window.addEventListener('resize', resetLogo);

function resetLogo() {
  if (window.innerWidth <= 767) {
    if (hamburgerMenuIsActive) {
      logo.classList.add('is-not-visible');
      logo.classList.remove('is-visible');
    }
    else if (!hamburgerMenuIsActive) {
      logo.classList.add('is-visible');
      logo.classList.remove('is-not-visible');
    }
  }
  else if (window.innerWidth > 767) {
    logo.classList.add('is-visible');
    logo.classList.remove('is-not-visible');
  }
}


// -- VIDEO FUNCTIONALITY ---------------------------------------- //


const myVideo = document.getElementById('myvideo');
//const videoLoadingUI = document.getElementById('video-loading-wrapper');
const videoDisplayUI = document.getElementById('video-wrapper');
const videoPlayButton = document.getElementById('video-play-btn');
const videoPlayButtonWrapper = document.getElementById('video-play-btn-wrapper');


//captions for video
let jsonCaptions = [
    {
      "start": "00:00:00.150",
      "end": "00:00:07.000",
      "text": "Let's face it. Your company, your products, your services, and your ideas, need design."
  },
  {
      "start": "00:00:07.500",
      "end": "00:00:12.500",
      "text": "However, employing staff or hiring contractors can get expensive."
  },
  {
      "start": "00:00:13.000",
      "end": "00:00:21.000",
      "text": "Experienced employees are now demanding upwards of over $100,000 per year plus benefits."
  },
  {
      "start": "00:00:21.150",
      "end": "00:00:26.000",
      "text": "And with freelancers, you never know exactly what you’re gonna get, and when."
  },
  {
      "start": "00:00:26.250",
      "end": "00:00:33.000",
      "text": "Maybe you’ve considered hiring a big design agency to get the top-tier quality work that your business deserves."
  },
  {
      "start": "00:00:33.500",
      "end": "00:00:37.000",
      "text": "But we won’t even begin to talk about how expensive that can be."
  },
  {
      "start": "00:00:37.250",
      "end": "00:00:47.500",
      "text": "And with all of these options, you still have the hassle of endless contract negotiations and time-consuming meetings when you just need to get something done."
  },
  {
      "start": "00:00:47.750",
      "end": "00:00:49.500",
      "text": "Nobody has time for that."
  },
  {
      "start": "00:00:50.000",
      "end": "00:00:59.500",
      "text": "What if there was a better way to get professional, agency-quality design work, while protecting your time, and at a fraction of the price?"
  },
  {
      "start": "00:01:00.000",
      "end": "00:01:03.250",
      "text": "Introducing… Design Magic."
  },
  {
      "start": "00:01:03.750",
      "end": "00:01:09.000",
      "text": "Your personal, in-house design agency that just works, like magic!"
  },
  {
      "start": "00:01:09.250",
      "end": "00:01:11.000",
      "text": "So, how do we do it?"
  },
  {
      "start": "00:01:11.250",
      "end": "00:01:17.500",
      "text": "With Design Magic we offer design subscription plans that take the guesswork out of the process."
  },
  {
      "start": "00:01:18.000",
      "end": "00:01:24.500",
      "text": "With a design subscription, you’ll be able to eliminate those time-consuming contract negotiations and meetings…"
  },
  {
      "start": "00:01:24.750",
      "end": "00:01:27.500",
      "text": "receive unlimited design requests…"
  },
  {
      "start": "00:01:27.750",
      "end": "00:01:34.500",
      "text": "and receive professional quality design work all for one convenient, low monthly price."
  },
  {
      "start": "00:01:35.000",
      "end": "00:01:37.250",
      "text": "So how does it all work?"
  },
  {
      "start": "00:01:37.500",
      "end": "00:01:41.500",
      "text": "First, subscribe to a design plan that’s right for your business."
  },
  {
      "start": "00:01:42.000",
      "end": "00:01:47.500",
      "text": "You’ll immediately be paired with one of our expert designers who will service your requests."
  },
  {
      "start": "00:01:47.750",
      "end": "00:01:50.500",
      "text": "Submit unlimited design requests."
  },
  {
      "start": "00:01:50.750",
      "end": "00:01:57.500",
      "text": "If a design request is too large, your designer will work with you to break it down into more manageable requests."
  },
  {
      "start": "00:01:58.000",
      "end": "00:02:06.000",
      "text": "All requests are worked on and delivered on a one-at-a time basis to ensure the quality that your business deserves."
  },
  {
      "start": "00:02:06.250",
      "end": "00:02:09.000",
      "text": "Have a question or want to share your ideas?"
  },
  {
      "start": "00:02:09.500",
      "end": "00:02:14.500",
      "text": "All communication, questions, and collaboration will be handled through Slack."
  },
  {
      "start": "00:02:14.750",
      "end": "00:02:20.000",
      "text": "This will reduce the need for lengthy meetings and give you more of your time back."
  },
  {
      "start": "00:02:20.500",
      "end": "00:02:28.000",
      "text": "Once all of the information is gathered, your designer will work their magic and bring your design request to life."
  },
  {
      "start": "00:02:29.000",
      "end": "00:02:33.500",
      "text": "You’ll receive updates to your design request in 1-2 business days."
  },
  {
      "start": "00:02:33.750",
      "end": "00:02:40.000",
      "text": "If you are not 100% satisfied with your design, we’ll keep refining it until you are."
  },
  {
      "start": "00:02:40.250",
      "end": "00:02:46.750",
      "text": "Still have questions or want to learn more? Feel free to speak to one of our representatives in the chat below."
  },
  {
      "start": "00:02:47.000",
      "end": "00:02:53.500",
      "text": "We can’t wait to hear more about your business and design needs and we look forward to working with you."
  },
  {
      "start": "00:02:53.750",
      "end": "00:03:01.000",
      "text": "Design Magic – Your personal, in-house design agency that works, like magic!"
  }
];


// Assuming `jsonCaptions` is your JSON data
function convertJsonToVtt(jsonCaptions) {
  let vttString = 'WEBVTT\n\n';
  jsonCaptions.forEach((caption, index) => {
      vttString += `${index + 1}\n${caption.start} --> ${caption.end}\n${caption.text}\n\n`;
  });
  return vttString;
}

function createDataUrl(vttString) {
  let encodedVtt = encodeURIComponent(vttString);
  return `data:text/vtt;charset=utf-8,${encodedVtt}`;
}

// Convert JSON to VTT and then to Data URL
let vttString = convertJsonToVtt(jsonCaptions);
let vttDataUrl = createDataUrl(vttString);

// Set the data URL as the source of the track element
let trackElement = document.createElement('track');
trackElement.setAttribute('kind', 'subtitles');
trackElement.setAttribute('label', 'English');
trackElement.setAttribute('srclang', 'en');
trackElement.setAttribute('src', vttDataUrl);
myVideo.appendChild(trackElement);

//

var videoHasStarted = false;

//hides the default video controls initially when the page loads
myVideo.controls = false;

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}


/*
myVideo.addEventListener('loadeddata', (e) => {
  if(myVideo.readyState >= 3){
      if (isMobileDevice()) {
          displayVideoUI();
          console.log("The browser is a Mobile version");
      } else {
          setTimeout(displayVideoUI, 1000);
          console.log("The browser is a Desktop version");
      }
  }
});
*/

function displayVideoUI() {
  console.log("displayVideoUI() is being called")
  //videoLoadingUI.style.display = "none";
  //videoLoadingUI.style.visibility = "none";
  videoDisplayUI.style.display = "block";
  videoDisplayUI.style.visibility = "visible";
}

videoDisplayUI.addEventListener("mouseover", hoverOverVideo);
videoDisplayUI.addEventListener("mouseout", hoverOutOfVideo);
videoDisplayUI.addEventListener("click", clickVideo);

myVideo.addEventListener("play", (event) => {
  animateOutTheWizDesktopPartially();
  logEvent(analytics, 'video_played');
});

myVideo.addEventListener("pause", (event) => { animateInTheWizDesktop(); });

myVideo.addEventListener("ended", (event) => {
  logEvent(analytics, 'video_completed');
  animateInTheWizDesktop();
});


function hoverOverVideo() {
  if (myVideo.currentTime == 0) {
    if (window.innerWidth <= 767) {
      videoPlayButton.style.width = 95 + "px";
      videoPlayButton.style.height = 95 + "px";
    }
    if (window.innerWidth > 767 && window.innerWidth <= 1024) {
      videoPlayButton.style.width = 120 + "px";
      videoPlayButton.style.height = 120 + "px";
    }
    if (window.innerWidth > 1023) {
      videoPlayButton.style.width = 145 + "px";
      videoPlayButton.style.height = 145 + "px";
    }
  }
}

function hoverOutOfVideo() {

  if (myVideo.currentTime == 0) {
    if (window.innerWidth <= 767) {
      videoPlayButton.style.width = 75 + "px";
      videoPlayButton.style.height = 75 + "px";
    }
    else if (window.innerWidth > 767 && window.innerWidth <= 1024) {
      videoPlayButton.style.width = 100 + "px";
      videoPlayButton.style.height = 100 + "px";
    }
    else if (window.innerWidth > 1023) {
      videoPlayButton.style.width = 125 + "px";
      videoPlayButton.style.height = 125 + "px";
    }
  }
}

window.addEventListener('resize', resetPlayButtonSize);

function resetPlayButtonSize() {
  
  if (window.innerWidth <= 767) {
    videoPlayButton.style.width = 75 + "px";
    videoPlayButton.style.height = 75 + "px";
  }
  else if (window.innerWidth > 767 && window.innerWidth < 1024) {
    videoPlayButton.style.width = 100 + "px";
    videoPlayButton.style.height = 100 + "px";
  }
  else if (window.innerWidth > 1023) {
    videoPlayButton.style.width = 125 + "px";
    videoPlayButton.style.height = 125 + "px";
  }
}

function clickVideo() {mobileNavHome
  if (!videoHasStarted) {
    videoHasStarted = true;
    videoPlayButton.classList.add('video-play-btn-fade-out');
    setTimeout(activateDefaultVideoControls, 350);
    myVideo.play();
  }
}

function activateDefaultVideoControls() {
  videoPlayButtonWrapper.style.display = 'hidden';
  myVideo.controls = true;
}


// -- SERVICES DROPDOWN MENU FUNCTIONALITY ---------------------------- //

const servicesDropdown = document.getElementById('services-dropdown');
const servicesDropdownTapTarget = document.getElementById('services-dropdown-tap-target');
const servicesDropdownScreenReaderText = document.getElementById('services-dropdown-screen-reader-text');
const servicesCat1 = document.getElementById('service-category-1');
const servicesCat2 = document.getElementById('service-category-2');
const servicesCat3 = document.getElementById('service-category-3');
const servicesCat4 = document.getElementById('service-category-4');
const servicesDropdownCaret = document.getElementById('services-dropdown-caret');
const servicesUnderline = document.getElementById('services-underline');
const servicesCatUl = document.querySelectorAll('.services-cat-ul');
const servicesCatBranding = document.getElementById('services-category-branding');
const servicesCatAppWeb = document.getElementById('services-category-app-web');
const servicesCatGraphics = document.getElementById('services-category-graphics');
const servicesCatOther = document.getElementById('services-category-other');

var activeCategory = 'Branding';

window.addEventListener('resize', SetCategoryLabelsAndCollapseMobileDropdown);

function SetCategoryLabelsAndCollapseMobileDropdown() {

  if (window.innerWidth < 768) {

    switch(activeCategory) {
      case 'Branding':
        servicesCat1.innerHTML = 'Branding';
        servicesCat2.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
        servicesCat3.innerHTML = 'Graphics';
        servicesCat4.innerHTML = 'Other';
        break;
      case 'App / Web':
        servicesCat1.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
        servicesCat2.innerHTML = 'Branding';
        servicesCat3.innerHTML = 'Graphics';
        servicesCat4.innerHTML = 'Other';
        break;
      case 'Graphics':
        servicesCat1.innerHTML = 'Graphics';
        servicesCat2.innerHTML = 'Branding';
        servicesCat3.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
        servicesCat4.innerHTML = 'Other';
        break;
      case 'Other':
        servicesCat1.innerHTML = 'Other';
        servicesCat2.innerHTML = 'Branding';
        servicesCat3.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
        servicesCat4.innerHTML = 'Graphics';
        break;
    }
  }

  if (window.innerWidth > 767) {
    //reset inner HTML of category labels on tablet / desktop
    servicesCat1.innerHTML = 'Branding';
    servicesCat2.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
    servicesCat3.innerHTML = 'Graphics';
    servicesCat4.innerHTML = 'Other';

    //set the underline
    switch(activeCategory) {

      case 'Branding' :
        servicesCat2.classList.remove('active');
        servicesCat3.classList.remove('active');
        servicesCat4.classList.remove('active');
        servicesCat1.classList.add('active');
        servicesUnderline.classList.remove('services-underline-position-2');
        servicesUnderline.classList.remove('services-underline-position-3');
        servicesUnderline.classList.remove('services-underline-position-4');
        servicesUnderline.classList.add('services-underline-position-1');
        break;

      case 'App / Web' :
        servicesCat1.classList.remove('active');
        servicesCat3.classList.remove('active');
        servicesCat4.classList.remove('active');
        servicesCat2.classList.add('active');
        servicesUnderline.classList.remove('services-underline-position-1');
        servicesUnderline.classList.remove('services-underline-position-3');
        servicesUnderline.classList.remove('services-underline-position-4');
        servicesUnderline.classList.add('services-underline-position-2');
        break;

      case 'Graphics' :
        servicesCat1.classList.remove('active');
        servicesCat2.classList.remove('active');
        servicesCat4.classList.remove('active');
        servicesCat3.classList.add('active');
        servicesUnderline.classList.remove('services-underline-position-1');
        servicesUnderline.classList.remove('services-underline-position-2');
        servicesUnderline.classList.remove('services-underline-position-4');
        servicesUnderline.classList.add('services-underline-position-3');
        break;

      case 'Other' :
        servicesCat1.classList.remove('active');
        servicesCat2.classList.remove('active');
        servicesCat3.classList.remove('active');
        servicesCat4.classList.add('active');
        servicesUnderline.classList.remove('services-underline-position-1');
        servicesUnderline.classList.remove('services-underline-position-2');
        servicesUnderline.classList.remove('services-underline-position-3');
        servicesUnderline.classList.add('services-underline-position-4');
        break;
    }

    //collpase mobile dropdown if open
    if (servicesDropdown.classList.contains('expanded')) {
      servicesDropdownScreenReaderText.setAttribute('aria-expanded', 'false');
      servicesDropdownScreenReaderText.textContent = "Click to open";
      servicesDropdown.classList.remove('expanded');
      servicesDropdownCaret.classList.remove('up');
    }
  }
}

servicesDropdownTapTarget.addEventListener('click', function () {
  if (window.innerWidth < 768) {
    ExpandCollapseDropdown();
  }
});

servicesCat1.addEventListener('click', function () {

  if (window.innerWidth > 767) {

    if (activeCategory != 'Branding') {

      activeCategory = 'Branding';

      servicesCat1.innerHTML = 'Branding';
      servicesCat2.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat3.innerHTML = 'Graphics';
      servicesCat4.innerHTML = 'Other';

      servicesCat2.classList.remove('active');
      servicesCat3.classList.remove('active');
      servicesCat4.classList.remove('active');
      servicesCat1.classList.add('active');

      servicesUnderline.classList.remove('services-underline-position-2');
      servicesUnderline.classList.remove('services-underline-position-3');
      servicesUnderline.classList.remove('services-underline-position-4');
      servicesUnderline.classList.add('services-underline-position-1');

      WaitToUpdateCategoryContent(activeCategory);

    }
    logEvent(analytics, 'desktop_services_cat_branding_clicked');
  }
});

servicesCat2.addEventListener('click', function () {

  if (window.innerWidth < 768) {
    
    ExpandCollapseDropdown();

    if (activeCategory == 'Branding') {
      activeCategory = 'App / Web';
      logEvent(analytics, 'mobile_services_cat_app_web_clicked');
      servicesCat1.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat2.innerHTML = 'Branding';
      servicesCat3.innerHTML = 'Graphics';
      servicesCat4.innerHTML = 'Other';
    }
    else if (activeCategory == 'App / Web') {
      activeCategory = 'Branding';
      logEvent(analytics, 'mobile_services_cat_branding_clicked');
      servicesCat1.innerHTML = 'Branding';
      servicesCat2.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat3.innerHTML = 'Graphics';
      servicesCat4.innerHTML = 'Other';
    }
    else if (activeCategory == 'Graphics') {
      activeCategory = 'Branding';
      logEvent(analytics, 'mobile_services_cat_branding_clicked');
      servicesCat1.innerHTML = 'Branding';
      servicesCat2.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat3.innerHTML = 'Graphics';
      servicesCat4.innerHTML = 'Other';
    }
    else if (activeCategory == 'Other') {
      activeCategory = 'Branding';
      servicesCat1.innerHTML = 'Branding';
      logEvent(analytics, 'mobile_services_cat_branding_clicked');
      servicesCat2.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat3.innerHTML = 'Graphics';
      servicesCat4.innerHTML = 'Other';
    }

    WaitToUpdateCategoryContent(activeCategory);

  }

  if (window.innerWidth > 767) {

    if (activeCategory != 'App / Web') {

      activeCategory = 'App / Web';

      servicesCat1.innerHTML = 'Branding';
      servicesCat2.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat3.innerHTML = 'Graphics';
      servicesCat4.innerHTML = 'Other';

      servicesCat1.classList.remove('active');
      servicesCat3.classList.remove('active');
      servicesCat4.classList.remove('active');
      servicesCat2.classList.add('active');

      servicesUnderline.classList.remove('services-underline-position-1');
      servicesUnderline.classList.remove('services-underline-position-3');
      servicesUnderline.classList.remove('services-underline-position-4');
      servicesUnderline.classList.add('services-underline-position-2');

      WaitToUpdateCategoryContent(activeCategory);

    }

    logEvent(analytics, 'desktop_services_cat_app_web_clicked');
  }
});



servicesCat3.addEventListener('click', function () {

  if (window.innerWidth < 768) {
    
    ExpandCollapseDropdown();

    if (activeCategory == 'Branding') {
      activeCategory = 'Graphics';
      logEvent(analytics, 'mobile_services_cat_graphics_clicked');
      servicesCat1.innerHTML = 'Graphics';
      servicesCat2.innerHTML = 'Branding';
      servicesCat3.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat4.innerHTML = 'Other';
    }
    else if (activeCategory == 'Graphics') {
      activeCategory = 'App / Web';
      logEvent(analytics, 'mobile_services_cat_app_web_clicked');
      servicesCat1.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat2.innerHTML = 'Branding';
      servicesCat3.innerHTML = 'Graphics';
      servicesCat4.innerHTML = 'Other';
    }
    else if (activeCategory == 'App / Web') {
      activeCategory = 'Graphics';
      logEvent(analytics, 'mobile_services_cat_graphics_clicked');
      servicesCat1.innerHTML = 'Graphics';
      servicesCat2.innerHTML = 'Branding';
      servicesCat3.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat4.innerHTML = 'Other';
    }
    else if (activeCategory == 'Other') {
      activeCategory = 'App / Web';
      logEvent(analytics, 'mobile_services_app_web_clicked');
      servicesCat1.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat2.innerHTML = 'Branding';
      servicesCat3.innerHTML = 'Graphics';
      servicesCat4.innerHTML = 'Other';
    }

    WaitToUpdateCategoryContent(activeCategory);

  }

  if (window.innerWidth > 767) {

    if (activeCategory != 'Graphics') {

      activeCategory = 'Graphics';

      servicesCat1.innerHTML = 'Branding';
      servicesCat2.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat3.innerHTML = 'Graphics';
      servicesCat4.innerHTML = 'Other';

      servicesCat1.classList.remove('active');
      servicesCat2.classList.remove('active');
      servicesCat4.classList.remove('active');
      servicesCat3.classList.add('active');

      servicesUnderline.classList.remove('services-underline-position-1');
      servicesUnderline.classList.remove('services-underline-position-2');
      servicesUnderline.classList.remove('services-underline-position-4');
      servicesUnderline.classList.add('services-underline-position-3');

      WaitToUpdateCategoryContent(activeCategory);

    }

    logEvent(analytics, 'desktop_services_cat_graphics_clicked');
  }

});



servicesCat4.addEventListener('click', function () {

  if (window.innerWidth < 768) {
    
    ExpandCollapseDropdown();

    if (activeCategory == 'Branding') {
      activeCategory = 'Other';
      logEvent(analytics, 'mobile_services_other_clicked');
      servicesCat1.innerHTML = 'Other';
      servicesCat2.innerHTML = 'Branding';
      servicesCat3.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat4.innerHTML = 'Graphics';
      
    }
    else if (activeCategory == 'App / Web') {
      activeCategory = 'Other';
      logEvent(analytics, 'mobile_services_other_clicked');
      servicesCat1.innerHTML = 'Other';
      servicesCat2.innerHTML = 'Branding';
      servicesCat3.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat4.innerHTML = 'Graphics';
    }
    else if (activeCategory == 'Graphics') {
      activeCategory = 'Other';
      logEvent(analytics, 'mobile_services_other_clicked');
      servicesCat1.innerHTML = 'Other';
      servicesCat2.innerHTML = 'Branding';
      servicesCat3.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat4.innerHTML = 'Graphics';
    }
    else if (activeCategory == 'Other') {
      activeCategory = 'Graphics';
      logEvent(analytics, 'mobile_graphics_clicked');
      servicesCat1.innerHTML = 'Graphics';
      servicesCat2.innerHTML = 'Branding';
      servicesCat3.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
      servicesCat4.innerHTML = 'Other';
    }

    WaitToUpdateCategoryContent(activeCategory);
  }

  if (window.innerWidth > 767) {

    if (activeCategory != 'Other') {
      activeCategory = 'Other';

    servicesCat1.innerHTML = 'Branding';
    servicesCat2.innerHTML = 'App <span style="font-family: Gotham-Light;">/</span> Web';
    servicesCat3.innerHTML = 'Graphics';
    servicesCat4.innerHTML = 'Other';

    servicesCat1.classList.remove('active');
    servicesCat2.classList.remove('active');
    servicesCat3.classList.remove('active');
    servicesCat4.classList.add('active');

    servicesUnderline.classList.remove('services-underline-position-1');
    servicesUnderline.classList.remove('services-underline-position-2');
    servicesUnderline.classList.remove('services-underline-position-3');
    servicesUnderline.classList.add('services-underline-position-4');
    
    WaitToUpdateCategoryContent(activeCategory);

    }

    logEvent(analytics, 'desktop_services_cat_other_clicked');
  }

  

});

function WaitToUpdateCategoryContent(category) {

  servicesCatUl.forEach((categorySection) => {
    //fade out services
    categorySection.classList.add('fade-services-out');
    categorySection.classList.remove('fade-services-in');
  });

  //wait to update category content
  setTimeout(function() { UpdateCategoryContent(category) }, 250);
}


function UpdateCategoryContent(category) {
  
  switch(category) {

    case "Branding":
      servicesCatBranding.classList.add('display');
      servicesCatAppWeb.classList.remove('display');
      servicesCatGraphics.classList.remove('display');
      servicesCatOther.classList.remove('display');
      break;

    case 'App / Web':
      servicesCatBranding.classList.remove('display');
      servicesCatAppWeb.classList.add('display');
      servicesCatGraphics.classList.remove('display');
      servicesCatOther.classList.remove('display');
      break;

    case "Graphics":
      servicesCatBranding.classList.remove('display');
      servicesCatAppWeb.classList.remove('display');
      servicesCatGraphics.classList.add('display');
      servicesCatOther.classList.remove('display');
      break;

    case "Other":
      servicesCatBranding.classList.remove('display');
      servicesCatAppWeb.classList.remove('display');
      servicesCatGraphics.classList.remove('display');
      servicesCatOther.classList.add('display');
      break;
  }

  servicesCatUl.forEach((categorySection) => {
    //fade in services
    categorySection.classList.add('fade-services-in');
    categorySection.classList.remove('fade-services-out');
  });

}

function ExpandCollapseDropdown() {
  servicesDropdown.classList.toggle('expanded');
  servicesDropdownCaret.classList.toggle('up');

  if (servicesDropdownScreenReaderText.getAttribute('aria-expanded') == 'false') {
    servicesDropdownScreenReaderText.setAttribute('aria-expanded', 'true');
    servicesDropdownScreenReaderText.textContent = "Click to close";
  }
  else {
    servicesDropdownScreenReaderText.setAttribute('aria-expanded', 'false');
    servicesDropdownScreenReaderText.textContent = "Click to open";
  }
}

// -- PRICING ------------------------------------------------ //

/*
const radioSection1 = document.getElementById("radio-section-1");
const radioSection2 = document.getElementById("radio-section-2");
const radioSection3 = document.getElementById("radio-section-3");

const radio1 = document.getElementById("radio1");
const radio2 = document.getElementById("radio2");
const radio3 = document.getElementById("radio3");

radioSection1.addEventListener('click', function () {
  if (window.innerWidth < 768) {
    radio1.checked = true;
    updatePricing('small');
  }
});

radioSection2.addEventListener('click', function () {
  if (window.innerWidth < 768) {
    radio2.checked = true;
    updatePricing('medium');
  }
});

radioSection3.addEventListener('click', function () {
  if (window.innerWidth < 768) {
    radio3.checked = true;
    updatePricing('large');
  }
});
*/

var monthlyPricingURL = "https://buy.stripe.com/6oE9BWeTT2IK6fm4gg"
var quarterlyPricingURL = "https://buy.stripe.com/8wMcO8h21cjk47e4gh"
var yearlyPricingURL = "https://buy.stripe.com/9AQ9BWaDD2IK33a002"

/*
radio1.addEventListener('click', function () { updatePricing('small'); });
radio2.addEventListener('click', function () { updatePricing('medium'); });
radio3.addEventListener('click', function () { updatePricing('large'); });


function updatePricing(companySize) {
  switch(companySize) {

    case 'small':
      document.getElementById("price-1").innerHTML = "$1495";
      document.getElementById("price-2").innerHTML = "$1245";
      document.getElementById("price-3").innerHTML = "$995";
      monthlyPricingURL = "https://buy.stripe.com/test_cN23gd88L7lHfTyaEE"
      quarterlyPricingURL = "https://buy.stripe.com/test_4gwg2Z4Wz9tP4aQfZ1"
      yearlyPricingURL = "https://buy.stripe.com/test_fZecQNgFh21n6iYbIK" 
      break;

    case 'medium':
      document.getElementById("price-1").innerHTML = "$2995";
      document.getElementById("price-2").innerHTML = "$2495";
      document.getElementById("price-3").innerHTML = "$1995";
      monthlyPricingURL = "https://buy.stripe.com/test_fZebMJ2OraxTbDieUY"
      quarterlyPricingURL = "https://buy.stripe.com/test_3csg2Z0GjbBX5eU8wB"
      yearlyPricingURL = "https://buy.stripe.com/test_5kAdURgFhcG1gXCaEK"
      break;

    case 'large':
      document.getElementById("price-1").innerHTML = "$4995";
      document.getElementById("price-2").innerHTML = "$4495";
      document.getElementById("price-3").innerHTML = "$3995";
      monthlyPricingURL = "https://buy.stripe.com/test_7sIg2Z1Kn8pLdLq28f"
      quarterlyPricingURL = "https://buy.stripe.com/test_6oE2c9cp121n5eU4go"
      yearlyPricingURL = "https://buy.stripe.com/test_14k1850Gj7lH8r64gp"
      break;
  }
}
*/

const subBtn1a = document.getElementById("subBtn1a");
const subBtn2a = document.getElementById("subBtn2a");
const subBtn3a = document.getElementById("subBtn3a");
const subBtn1b = document.getElementById("subBtn1b");
const subBtn2b = document.getElementById("subBtn2b");
const subBtn3b = document.getElementById("subBtn3b");

subBtn1a.addEventListener('click', function () { LinkToPayment('monthly'); logEvent(analytics, 'subscribe_cta_monthly_clicked'); });
subBtn2a.addEventListener('click', function () { LinkToPayment('quarterly'); logEvent(analytics, 'subscribe_cta_quarterly_clicked'); });
subBtn3a.addEventListener('click', function () { LinkToPayment('yearly'); logEvent(analytics, 'subscribe_cta_yearly_clicked'); });
subBtn1b.addEventListener('click', function () { LinkToPayment('monthly'); logEvent(analytics, 'subscribe_cta_monthly_clicked'); });
subBtn2b.addEventListener('click', function () { LinkToPayment('quarterly'); logEvent(analytics, 'subscribe_cta_quarterly_clicked'); });
subBtn3b.addEventListener('click', function () { LinkToPayment('yearly'); logEvent(analytics, 'subscribe_cta_yearly_clicked'); });

function LinkToPayment(occurrance) {
  switch(occurrance) {
    case 'monthly': window.open(monthlyPricingURL); break;
    case 'quarterly': window.open(quarterlyPricingURL); break;
    case 'yearly': window.open(yearlyPricingURL); break;
  }
}




// -- FAQ ------------------------------------------------ //


//--- Question / Answer 1 ---//


const q1 = document.querySelector('.q-1');
const a1 = document.querySelector('.a-1');
const a1Text = document.querySelector('.a-1-text');
const exp1 = document.querySelector('.exp-1');

q1.addEventListener('click', function () {

  //adjust the height of the answer
  if (a1.offsetHeight == 0) {
    a1.style.height = a1Text.offsetHeight + 20 + "px";
    logEvent(analytics, 'faq_why_subscribe_opened');
  }
  else {
    a1.style.height = 0;
    logEvent(analytics, 'faq_why_subscribe_closed');
  }

  //toggle the expander UI
  exp1.classList.toggle('is-active');

  //collapse any other expanded questions
  if (a2.offsetHeight > 0) { a2.style.height = 0; exp2.classList.toggle('is-active'); }
  if (a2b.offsetHeight > 0) { a2b.style.height = 0; exp2b.classList.toggle('is-active'); }
  if (a3.offsetHeight > 0) { a3.style.height = 0; exp3.classList.toggle('is-active'); }
  if (a4.offsetHeight > 0) { a4.style.height = 0; exp4.classList.toggle('is-active'); }
  if (a5.offsetHeight > 0) { a5.style.height = 0; exp5.classList.toggle('is-active'); }
  if (a6.offsetHeight > 0) { a6.style.height = 0; exp6.classList.toggle('is-active'); }
  if (a7.offsetHeight > 0) { a7.style.height = 0; exp7.classList.toggle('is-active'); }

});


//--- Question / Answer 2 ---//


const q2 = document.querySelector('.q-2');
const a2 = document.querySelector('.a-2');
const a2Text = document.querySelector('.a-2-text');
const exp2 = document.querySelector('.exp-2');

q2.addEventListener('click', function () {

  //adjust the height of the answer
  if (a2.offsetHeight == 0) {
    a2.style.height = a2Text.offsetHeight + 20 + "px";
    logEvent(analytics, 'faq_how_process_works_opened');
  }
  else {
    a2.style.height = 0;
    logEvent(analytics, 'faq_how_process_works_closed');
  }

  //toggle the expander UI
  exp2.classList.toggle('is-active');

  //collapse any other expanded questions
  if (a1.offsetHeight > 0) { a1.style.height = 0; exp1.classList.toggle('is-active'); }
  if (a2b.offsetHeight > 0) { a2b.style.height = 0; exp2b.classList.toggle('is-active'); }
  if (a3.offsetHeight > 0) { a3.style.height = 0; exp3.classList.toggle('is-active'); }
  if (a4.offsetHeight > 0) { a4.style.height = 0; exp4.classList.toggle('is-active'); }
  if (a5.offsetHeight > 0) { a5.style.height = 0; exp5.classList.toggle('is-active'); }
  if (a6.offsetHeight > 0) { a6.style.height = 0; exp6.classList.toggle('is-active'); }
  if (a7.offsetHeight > 0) { a7.style.height = 0; exp7.classList.toggle('is-active'); }

});

//--- Question / Answer 2b ---//


const q2b = document.querySelector('.q-2b');
const a2b = document.querySelector('.a-2b');
const a2bText = document.querySelector('.a-2b-text');
const exp2b = document.querySelector('.exp-2b');

q2b.addEventListener('click', function () {

  //adjust the height of the answer
  if (a2b.offsetHeight == 0) {
    a2b.style.height = a2bText.offsetHeight + 20 + "px";
    logEvent(analytics, 'faq_one_designer_opened');
  }
  else {
    a2b.style.height = 0;
    logEvent(analytics, 'faq_one_designer_closed');
  }

  //toggle the expander UI
  exp2b.classList.toggle('is-active');

  //collapse any other expanded questions
  if (a1.offsetHeight > 0) { a1.style.height = 0; exp1.classList.toggle('is-active'); }
  if (a2.offsetHeight > 0) { a2.style.height = 0; exp2.classList.toggle('is-active'); }
  if (a3.offsetHeight > 0) { a3.style.height = 0; exp3.classList.toggle('is-active'); }
  if (a4.offsetHeight > 0) { a4.style.height = 0; exp4.classList.toggle('is-active'); }
  if (a5.offsetHeight > 0) { a5.style.height = 0; exp5.classList.toggle('is-active'); }
  if (a6.offsetHeight > 0) { a6.style.height = 0; exp6.classList.toggle('is-active'); }
  if (a7.offsetHeight > 0) { a7.style.height = 0; exp7.classList.toggle('is-active'); }

});


//--- Question / Answer 3 ---//

const q3 = document.querySelector('.q-3');
const a3 = document.querySelector('.a-3');
const a3Text = document.querySelector('.a-3-text');
const exp3 = document.querySelector('.exp-3');

q3.addEventListener('click', function () {

  //adjust the height of the answer
  if (a3.offsetHeight == 0) {
    a3.style.height = a3Text.offsetHeight + 20 + "px";
    logEvent(analytics, 'faq_how_many_requests_opened');
  }
  else {
    a3.style.height = 0;
    logEvent(analytics, 'faq_how_many_requests_closed');
  }

  //toggle the expander UI
  exp3.classList.toggle('is-active');

  //collapse any other expanded questions
  if (a1.offsetHeight > 0) { a1.style.height = 0; exp1.classList.toggle('is-active'); }
  if (a2.offsetHeight > 0) { a2.style.height = 0; exp2.classList.toggle('is-active'); }
  if (a2b.offsetHeight > 0) { a2b.style.height = 0; exp2b.classList.toggle('is-active'); }
  if (a4.offsetHeight > 0) { a4.style.height = 0; exp4.classList.toggle('is-active'); }
  if (a5.offsetHeight > 0) { a5.style.height = 0; exp5.classList.toggle('is-active'); }
  if (a6.offsetHeight > 0) { a6.style.height = 0; exp6.classList.toggle('is-active'); }
  if (a7.offsetHeight > 0) { a7.style.height = 0; exp7.classList.toggle('is-active'); }
  
});


//--- Question / Answer 4 ---//


const q4 = document.querySelector('.q-4');
const a4 = document.querySelector('.a-4');
const a4Text = document.querySelector('.a-4-text');
const exp4 = document.querySelector('.exp-4');

q4.addEventListener('click', function () {

  //adjust the height of the answer
  if (a4.offsetHeight == 0) {
    a4.style.height = a4Text.offsetHeight + 20 + "px";
    logEvent(analytics, 'faq_when_see_progress_opened');
  }
  else {
    a4.style.height = 0;
    logEvent(analytics, 'faq_when_see_progress_closed');
  }

  //toggle the expander UI
  exp4.classList.toggle('is-active');

  //collapse any other expanded questions
  if (a1.offsetHeight > 0) { a1.style.height = 0; exp1.classList.toggle('is-active'); }
  if (a2.offsetHeight > 0) { a2.style.height = 0; exp2.classList.toggle('is-active'); }
  if (a2b.offsetHeight > 0) { a2b.style.height = 0; exp2b.classList.toggle('is-active'); }
  if (a3.offsetHeight > 0) { a3.style.height = 0; exp3.classList.toggle('is-active'); }
  if (a5.offsetHeight > 0) { a5.style.height = 0; exp5.classList.toggle('is-active'); }
  if (a6.offsetHeight > 0) { a6.style.height = 0; exp6.classList.toggle('is-active'); }
  if (a7.offsetHeight > 0) { a7.style.height = 0; exp7.classList.toggle('is-active'); }
  
});


//--- Question / Answer 5 ---//


const q5 = document.querySelector('.q-5');
const a5 = document.querySelector('.a-5');
const a5Text = document.querySelector('.a-5-text');
const exp5 = document.querySelector('.exp-5');

q5.addEventListener('click', function () {

  //adjust the height of the answer
  if (a5.offsetHeight == 0) {
    a5.style.height = a5Text.offsetHeight + 20 + "px";
    logEvent(analytics, 'faq_what_if_not_satisfied_opened');
  }
  else {
    a5.style.height = 0;
    logEvent(analytics, 'faq_what_if_not_satisfied_closed');
  }

  //toggle the expander UI
  exp5.classList.toggle('is-active');

  //collapse any other expanded questions
  if (a1.offsetHeight > 0) { a1.style.height = 0; exp1.classList.toggle('is-active'); }
  if (a2.offsetHeight > 0) { a2.style.height = 0; exp2.classList.toggle('is-active'); }
  if (a2b.offsetHeight > 0) { a2b.style.height = 0; exp2b.classList.toggle('is-active'); }
  if (a3.offsetHeight > 0) { a3.style.height = 0; exp3.classList.toggle('is-active'); }
  if (a4.offsetHeight > 0) { a4.style.height = 0; exp4.classList.toggle('is-active'); }
  if (a6.offsetHeight > 0) { a6.style.height = 0; exp6.classList.toggle('is-active'); }
  if (a7.offsetHeight > 0) { a7.style.height = 0; exp7.classList.toggle('is-active'); }
  
});


//--- Question / Answer 6 ---//


const q6 = document.querySelector('.q-6');
const a6 = document.querySelector('.a-6');
const a6Text = document.querySelector('.a-6-text');
const exp6 = document.querySelector('.exp-6');

q6.addEventListener('click', function () {

  //adjust the height of the answer
  if (a6.offsetHeight == 0) {
    a6.style.height = a6Text.offsetHeight + 20 + "px";
    logEvent(analytics, 'faq_what_if_out_of_needs_opened');
  }
  else {
    a6.style.height = 0;
    logEvent(analytics, 'faq_what_if_out_of_needs_closed');
  }

  //toggle the expander UI
  exp6.classList.toggle('is-active');

  //collapse any other expanded questions
  if (a1.offsetHeight > 0) { a1.style.height = 0; exp1.classList.toggle('is-active'); }
  if (a2.offsetHeight > 0) { a2.style.height = 0; exp2.classList.toggle('is-active'); }
  if (a2b.offsetHeight > 0) { a2b.style.height = 0; exp2b.classList.toggle('is-active'); }
  if (a3.offsetHeight > 0) { a3.style.height = 0; exp3.classList.toggle('is-active'); }
  if (a4.offsetHeight > 0) { a4.style.height = 0; exp4.classList.toggle('is-active'); }
  if (a5.offsetHeight > 0) { a5.style.height = 0; exp5.classList.toggle('is-active'); }
  if (a7.offsetHeight > 0) { a7.style.height = 0; exp7.classList.toggle('is-active'); }
  
});


//--- Question / Answer 7 ---//


const q7 = document.querySelector('.q-7');
const a7 = document.querySelector('.a-7');
const a7Text = document.querySelector('.a-7-text');
const exp7 = document.querySelector('.exp-7');

q7.addEventListener('click', function () {

  //adjust the height of the answer
  if (a7.offsetHeight == 0) {
    a7.style.height = a7Text.offsetHeight + 20 + "px";
    logEvent(analytics, 'faq_more_info_opened');
  }
  else {
    a7.style.height = 0;
    logEvent(analytics, 'faq_more_info_closed');
  }

  //toggle the expander UI
  exp7.classList.toggle('is-active');

  //collapse any other expanded questions
  if (a1.offsetHeight > 0) { a1.style.height = 0; exp1.classList.toggle('is-active'); }
  if (a2.offsetHeight > 0) { a2.style.height = 0; exp2.classList.toggle('is-active'); }
  if (a2b.offsetHeight > 0) { a2b.style.height = 0; exp2b.classList.toggle('is-active'); }
  if (a3.offsetHeight > 0) { a3.style.height = 0; exp3.classList.toggle('is-active'); }
  if (a4.offsetHeight > 0) { a4.style.height = 0; exp4.classList.toggle('is-active'); }
  if (a5.offsetHeight > 0) { a5.style.height = 0; exp5.classList.toggle('is-active'); }
  if (a6.offsetHeight > 0) { a6.style.height = 0; exp6.classList.toggle('is-active'); }

});


//adjust the height of the expanded Answers on window resize
window.addEventListener('resize', adjustFAQanswerHeight);
window.addEventListener('resize', handleHeaderColors);


function adjustFAQanswerHeight() {

  if (a1.offsetHeight > 0) { a1.style.height = a1Text.offsetHeight + 20 + "px"; }

  if (a2.offsetHeight > 0) {  a2.style.height = a2Text.offsetHeight + 20 + "px"; }

  if (a2b.offsetHeight > 0) {  a2b.style.height = a2bText.offsetHeight + 20 + "px"; }

  if (a3.offsetHeight > 0) { a3.style.height = a3Text.offsetHeight + 20 + "px"; }

  if (a4.offsetHeight > 0) { a4.style.height = a4Text.offsetHeight + 20 + "px"; }

  if (a5.offsetHeight > 0) { a5.style.height = a5Text.offsetHeight + 20 + "px"; }

  if (a6.offsetHeight > 0) { a6.style.height = a6Text.offsetHeight + 20 + "px"; }

  if (a7.offsetHeight > 0) { a7.style.height = a7Text.offsetHeight + 20 + "px"; }

}

