import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, getDoc, setDoc, arrayUnion, onSnapshot } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';

//retrieve apiKey from environment variable and set const
const apiKey4Real = import.meta.env.VITE_FIREBASE_API_KEY;

//firebase config
const firebaseConfig = {
    apiKey: apiKey4Real, 
    authDomain: "chat-magic-752a8.firebaseapp.com",
    projectId: "chat-magic-752a8",
    storageBucket: "chat-magic-752a8.appspot.com",
    messagingSenderId: "384802000917",
    appId: "1:384802000917:web:a196d269153ec1fb503e1c",
    measurementId: "G-ELYWZ1HQ5V"
};
  
//initialize firebase
const app = initializeApp(firebaseConfig);

//initialize the firestore
const db = getFirestore(app);

//initialize analytics
const analytics = getAnalytics(app);



//ui variables
const chatContainer = document.getElementById('magic-chat-container');
const chatBubbleSmlBtnContainer = document.getElementById('chat-bubble-sml-btn-container');
const chatBubbleSmlBtn = document.getElementById('chat-bubble-sml-btn');
const chatBubbleSmlBtnShadow = document.getElementById('chat-bubble-sml-btn-shadow');
const chatExpandedContainer = document.getElementById('chat-expanded-container');
const chatBubbleLg = document.getElementById('chat-bubble-lg');
const chatBubbleLgShadow = document.getElementById('chat-bubble-lg-shadow');
const chatCloseBtn = document.getElementById('chat-close-btn');
const chatInput = document.getElementById('chat-input');
const chatInputSubmit = document.getElementById('chat-input-submit');
const chatThread = document.getElementById('chat-thread');
const myForm = document.getElementById('myForm');


/*-- OPEN THE CHAT --*/

chatBubbleSmlBtnContainer.addEventListener('click', () => {

    // when I click the chat bubble button, I want to...

    // animate out the small chat bubble button
    chatBubbleSmlBtn.classList.remove('chat-bubble-sml-btn-anim-in');
    chatBubbleSmlBtn.classList.add('chat-bubble-sml-btn-anim-out');

    // fade out the small chat bubble button shadow
    chatBubbleSmlBtnShadow.classList.remove('chat-bubble-sml-btn-shadow-fade-in');
    chatBubbleSmlBtnShadow.classList.add('chat-bubble-sml-btn-shadow-fade-out');

    // delay when the remaining animations fire by 150ms
    setTimeout(hideChatBubbleSmlBtnContainer, 150);

    // put the input in focus
    chatInput.focus();

    //log event
    logEvent(analytics, 'chat_opened');
});

function hideChatBubbleSmlBtnContainer() {
    
    // animate in the chat container
    chatContainer.classList.add('magic-chat-container-anim-in');
    
    // display the magic chat expanded container
    chatExpandedContainer.style.display = 'flex';
    chatBubbleSmlBtnContainer.style.display = "none";
    
    // animate in the large chat bubble
    chatBubbleLg.classList.remove('chat-bubble-lg-anim-out');
    chatBubbleLg.classList.add('chat-bubble-lg-anim-in');
    
    // fade in the large chat bubble shadow
    chatBubbleLgShadow.classList.remove('chat-bubble-lg-shadow-fade-out');
    chatBubbleLgShadow.classList.add('chat-bubble-lg-shadow-fade-in');
    
    // animate in the close button
    chatCloseBtn.classList.remove('chat-close-btn-anim-out');
    chatCloseBtn.classList.add('chat-close-btn-anim-in');

    // reveal the chat thread
    chatThread.classList.add('chat-thread-reveal');

    // reveal the input area
    myForm.classList.add('myForm-reveal');
    
    // wait until the 'chat-close-btn-anim-in' animation
    // is finished and reset the state of the close button
    setTimeout(resetChatCloseBtn, 300);
    setTimeout(waitToHandleChatSession, 300);
}

// reset the state of the close button
function resetChatCloseBtn() {
    chatCloseBtn.classList.remove('chat-close-btn-anim-in');
    chatCloseBtn.classList.add('chat-close-btn-displayed');
}

// wait until the chat is opened and call handleChatSession
function waitToHandleChatSession() {
    handleChatSession();
}

// check if sessionID exists in local storage and handle accordingly
async function handleChatSession() {
  const sessionId = localStorage.getItem('chatSessionId');
  
  if (sessionId) {
      const sessionRef = doc(db, 'chatSessions', sessionId);
      const docSnap = await getDoc(sessionRef);
      
      if (docSnap.exists() && !docSnap.data().isExpired) {
          loadMessages(sessionId);
          listenForChatSessionUpdates(sessionId);
      } else {
          console.log("the chat session has expired");
          localStorage.removeItem('chatSessionId');
      }
  }
  // If no sessionID in localStorage, do nothing
}

// clear any chat bubbles that were previously loaded to solve for dupliction
function clearChat() {
    const chatBubbles = document.querySelectorAll('.chat-bubble');
  
    chatBubbles.forEach((bubble, index) => {
      if (index > 0) { // Skip the first element
        bubble.remove();
      }
    });
  }

//load messages from a valid session and listen for new messages
async function loadMessages(sessionId) {
    const sessionRef = doc(db, 'chatSessions', sessionId);
    const docSnap = await getDoc(sessionRef);

    if (docSnap.exists() && !docSnap.data().isExpired) {
        const messages = docSnap.data().messages;

        if (messages[messages.length - 1].message.value === messages[messages.length - 2].message.value) {
            console.log(messages[messages.length - 1].message);
            console.log(messages[messages.length - 2].message);
            console.log(messages);
        }

        messages.forEach(({ message, sender, timestamp }) => {
            if (sender === 'client') {
                addCustomerChatBubble(message);
            } else if (sender === 'design-magic') {
                addDesignMagicChatBubble(message);
            }
        });
    }   
}

function listenForChatSessionUpdates(sessionId) {
    const sessionRef = doc(db, 'chatSessions', sessionId);

    onSnapshot(sessionRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
            const sessionData = docSnapshot.data();
            // Check for new messages and if the source is 'slack'
            if (sessionData.messages && sessionData.messages.length) {
                if (sessionData.messages[sessionData.messages.length - 1].source === 'slack') {
                    addDesignMagicChatBubble(sessionData.messages[sessionData.messages.length - 1].message);
                }
            }
        }
    }, (err) => {
        console.error(`Encountered error: ${err}`);
    });
}

// Attach an event listener to the input field for user input
chatInputSubmit.addEventListener('click', handleButtonClick);

chatInput.addEventListener('keydown', function(event) {
    // Check if the pressed key is the Enter key (key code 13)
    if (event.keyCode === 13) {
      // Prevent the default behavior of the Enter key (e.g., form submission)
      event.preventDefault();
      handleButtonClick();
    }
  });

chatInput.addEventListener('input', function() {

    var inputValue = chatInput.value;

    if (inputValue.length < 1) {
        chatInputSubmit.classList.remove('chat-input-submit-purple');
    }
    else {
        chatInputSubmit.classList.add('chat-input-submit-purple');
    }

});

// Handle button click
function handleButtonClick() {

    // Get the value from the input field
    var inputValue = chatInput.value;

    if (inputValue) {

        // Check if the user input is not empty
        if (inputValue !== '') {
          
          // add a new customer chat bubble with the user input
          addCustomerChatBubble(inputValue);
          
          // handle adding chat input to the server
          handleChatInput(inputValue, "client");
  
          // Clear the input field
          chatInput.value = '';
        }
    }
}

// add Design Magic chat bubble
function 
addDesignMagicChatBubble(message) {

  // Create the outermost div with class 'chat-bubble dm'
  const chatBubble = document.createElement('div');
  chatBubble.className = 'chat-bubble dm';

  // Create the dm-icon-container div
  const iconContainer = document.createElement('div');
  iconContainer.className = 'dm-icon-container';

  // Create the dm-icon div
  const icon = document.createElement('div');
  icon.className = 'dm-icon';

  // Append the icon to the icon container
  iconContainer.appendChild(icon);

  // Create the dm-text-container div
  const textContainer = document.createElement('div');
  textContainer.className = 'dm-text-container';
  textContainer.textContent = message; // Set the message text

  // Append iconContainer and textContainer to chatBubble
  chatBubble.appendChild(iconContainer);
  chatBubble.appendChild(textContainer);
  chatThread.appendChild(chatBubble);

  removeDuplicateDesignMagicChatBubbles(textContainer.textContent);

  // Scroll the chat thread to the bottom
  chatThread.scrollTop = chatThread.scrollHeight;
}

function removeDuplicateDesignMagicChatBubbles(message) {
    // Get all the chat bubbles with class 'dm-text-container'
    const chatBubbles = document.querySelectorAll('.dm-text-container');

    // Iterate over the chat bubbles
    chatBubbles.forEach((bubble, index) => {
        if (index > 0) { // Skip the first bubble as it has no previous sibling
            const previousBubble = chatBubbles[index - 1];

            // If the current bubble's text matches the message and is the same as the previous bubble
            if (bubble.textContent === message && previousBubble.textContent === message) {
                // Remove the current duplicate bubble
                bubble.parentElement.remove();
                console.log("A duplicate bubble has been removed");
            }
        }
    });
}

// add customer/client chat bubble
function addCustomerChatBubble(message) {

    // Create a new chat bubble div
    var chatBubble = document.createElement('div');
    chatBubble.className = 'chat-bubble customer';

    // Create the text container wrapper
    var textContainerWrapper = document.createElement('div');
    textContainerWrapper.className = 'customer-text-container-wrapper';

    // Set the text content
    textContainerWrapper.textContent = message;

    // Append the text container wrapper to the chat bubble
    chatBubble.appendChild(textContainerWrapper);

    // Append the chat bubble to the container
    chatThread.appendChild(chatBubble);

    chatInputSubmit.classList.remove('chat-input-submit-purple');

    // Scroll the chat thread to the bottom
    chatThread.scrollTop = chatThread.scrollHeight;
}

// generate session ID
function generateSessionId() {
    const newDocRef = doc(collection(db, 'chatSessions'));
    return newDocRef.id;
}

  
// create a new chat session
async function createChatSession(sessionId, message, sender) {
  const sessionRef = doc(db, 'chatSessions', sessionId);

  let isExpiredBool = false;

  let timestamp = new Date();

  await setDoc(sessionRef, {
      createdAt: timestamp.getTime(),
      lastActive: timestamp.getTime(),
      isExpired: isExpiredBool,
      messages: [{
          sender: sender,
          message: message,
          timestamp: timestamp,
          source: 'liveChat'
      }]
  });
}
  
//add a message to an existing chat message
async function addMessageToChatSession(sessionId, message, sender) {
  
  const sessionRef = doc(db, 'chatSessions', sessionId);

  let timestamp = new Date();
  
  const messageData = {
      sender: sender,
      message: message,
      timestamp: timestamp,
      source: 'liveChat'
  };
  
  await setDoc(sessionRef, {
      lastActive: timestamp.getTime(),
      messages: arrayUnion(messageData)
  }, { merge: true });
}
  
// main function to handle chat input
async function handleChatInput(message, sender) {
  let sessionId = localStorage.getItem('chatSessionId');

  if (!sessionId) {
      sessionId = generateSessionId();
      localStorage.setItem('chatSessionId', sessionId);
      await createChatSession(sessionId, message, sender);
  } else {
      await addMessageToChatSession(sessionId, message, sender);
  }
  listenForChatSessionUpdates(sessionId);
}

/*-- CLOSE THE CHAT --*/

chatCloseBtn.addEventListener('click', () => {
    
    // when I click the close button, I want to...

    // animate out the large chat bubble
    chatBubbleLg.classList.remove('chat-bubble-lg-anim-in');
    chatBubbleLg.classList.add('chat-bubble-lg-anim-out');

    // animate out the large chat bubble shadow
    chatBubbleLgShadow.classList.remove('chat-bubble-lg-shadow-fade-in');
    chatBubbleLgShadow.classList.add('chat-bubble-lg-shadow-fade-out');

    // hide the chat thread
    chatThread.classList.remove('chat-thread-reveal');

    // hide the input area
    myForm.classList.remove('myForm-reveal');

    // animate out the close button
    chatCloseBtn.classList.remove('chat-close-btn-displayed');
    chatCloseBtn.classList.add('chat-close-btn-anim-out');

    // animate in the chat bubble sml button
    setTimeout(animInChatBubbleSmlBtn, 150);

    //log event
    logEvent(analytics, 'chat_closed');
});

function animInChatBubbleSmlBtn() {

    // reset the chat container, expanded container and sml chat bubble button
    chatContainer.classList.remove('magic-chat-container-anim-in');
    
    // reset the display of the 'chat-expanded-container'
    // and 'chat-bubble-sml-btn-container'
    chatExpandedContainer.style.display = 'inline';
    chatBubbleSmlBtnContainer.style.display = 'flex';
    
    // animate in the small chat bubble button
    chatBubbleSmlBtn.classList.remove('chat-bubble-sml-btn-anim-out');
    chatBubbleSmlBtn.classList.add('chat-bubble-sml-btn-anim-in');
    
    // fade in the small chat bubble button shadow
    chatBubbleSmlBtnShadow.classList.remove('chat-bubble-sml-btn-shadow-fade-out');
    chatBubbleSmlBtnShadow.classList.add('chat-bubble-sml-btn-shadow-fade-in');

    // wait until the 'chat-bubble-sml-btn-anim-in' animation
    // is finished and reset the state of the chat bubble small button
    setTimeout(resetChatBubbleSmlButton, 150);
    clearChat();
}

function resetChatBubbleSmlButton() {

    // reset the state of the close button
    chatBubbleSmlBtn.classList.remove('chat-bubble-sml-btn-anim-in');
}
